import React, { useState, useEffect } from "react";
import "./App.css"
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Homepage from "./components/Pages/Homepage";
// import Aboutus from "./components/Pages/Aboutus";
// import Form from "./components/Shared/Form/Form";
// import GiftingForm from "./components/Shared/FormGifting/Form";
import axios from "axios";
// import PeekInside from "./components/Pages/PeekInside";
// import SingleBox from "./components/Pages/SingleBox";

// import ScrollToTop from "./components/utilities/ScrollToTop";
// import TermsOfServices from "./components/Pages/TermsOfServices";
// import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
// import CookiePolicy from "./components/Pages/CookiePolicy";
// import ShippingCosts from "./components/Pages/ShippingCosts";
// import RefundPolicy from "./components/Pages/RefundPolicy";
// import Faqs from "./components/Pages/Faqs";
// import ContactUs from "./components/Pages/ContactUs";
import ReactPixel from "react-facebook-pixel";
// import { CookiesProvider } from "react-cookie";
// import Plans from "./components/Shared/Plans/Plans";
import Loader from "./components/utilities/Loader";
// import GiftingPlans from "./components/Shared/Plans/GiftingPlans";
// import Subscribe from "./components/Pages/Subscribe";
// import P404 from "./components/Shared/Pages/404";
// import { Singleblog } from "./components/Shared/Pages/Blog/singleblog";
// import OfferHomepage from "./components/Pages/OfferHomepage";
// import HeartInIreland from "./components/Shared/Pages/HeartInIreland/HeartinIreland";
// import SecondBlog from "./components/Shared/Pages/Blog/SecondBlog";
// import Active from "./components/Pages/active";
// import Letters from "./components/Pages/letters";
// import Christmas from "./components/Pages/Christmas";

// import Stories from "./components/Pages/Press/Stories";
// import SingleMessage from "./components/Pages/Press/SingleMessage";
// import FormStarted from "./components/Shared/FormStarted/Form";
// import FormStartedGift from "./components/Shared/FormGiftingStarted/Form";
// import EarlyHomepage from "./components/Pages/EarlyHomepage";

console.warn = console.error = () => {};

console.log(
  "%c Developed by: Black Bird Marketing https://blackbird.marketing",
  "padding:9px 5px; background:black; line-height:25px;color: white"
);

console.error("Error");
export default function App() {
  const [state, setState] = useState({
    posts: [],
    isLoaded: false,
  });
  const [peekInside, setPeekInside] = useState({
    inside: [],
    isLoaded: false,
  });

  const [founder, setFounder] = useState(true);

  useEffect(() => {
    axios
      .get("https://cms.myirelandbox.com/wp-json/wp/v2/pages?per_page=100")
      .then((res) =>
        setState({
          posts: res.data,
          isLoaded: true,
        })
      )
      .catch((err) => console.log(err));

    axios
      .get("https://cms.myirelandbox.com/wp-json/wp/v2/posts/")
      .then((res) =>
        setPeekInside({
          inside: res.data,
          isLoaded: true,
          refresh: 0,
        })
      )
      .catch((err) => console.log(err));
  }, []);

  var homepageId,
    formId,
    ourFounderId,
    privacyId,
    cookieId,
    termsId,
    faqId,
    refundId,
    shippingId,
    plans,
    giftingPlans,
    giftingForm,
    offerHome,
    newHomepage,
    Christmas2,
    early;
  state.posts.map((e) => {

    switch (e.id) {
      case 417:
        formId = e;
        break;
      case 365:
        cookieId = e;
        break;
      case 371:
        faqId = e;
        break;
      case 22:
        homepageId = e;
        break;
      case 3:
        privacyId = e;
        break;
      case 369:
        refundId = e;
        break;
      case 367:
        shippingId = e;
        break;
      case 357:
        termsId = e;
        break;
      case 448:
        ourFounderId = e;
        break;
      case 1243:
        plans = e;
        break;
      case 1495:
        giftingPlans = e;
        break;
      case 1664:
        giftingForm = e;
        break;
      case 1886:
        newHomepage = e;
        break;
      case 2632:
        offerHome = e;
        break;
        case 4110:
          Christmas2 = e;
          break;
          case 5021:
            early = e;
            break;
    }
  });

  ReactPixel.init("959667827420460");
  ReactPixel.pageView();

  if (state.isLoaded === false || peekInside.isLoaded === false)
    return <Loader />;
  else {
    return (
       
//       <CookiesProvider>
//         <Router>
//           <ScrollToTop>
//             <Routes>
            
//               <Route path="/" exact
//                 element={
//                   <Homepage
//                     newHp={newHomepage}
//                     posts={homepageId}
//                     setFounder={setFounder}
//                     boxes={peekInside.inside}
//                   />
//                 }
//               />
//                <Route path="/early-celebrations" exact
//                 element={
//                   <EarlyHomepage
//                     newHp={newHomepage}
//                     posts={homepageId}
//                     postsTwo={early}
//                     setFounder={setFounder}
//                     boxes={peekInside.inside}
//                   />
//                 }
//               />
              
//               <Route path="/experience-ireland" exact
//                 element={
//                   <Active
//                     newHp={newHomepage}
//                     posts={homepageId}
//                     setFounder={setFounder} 
//                     boxes={peekInside.inside}
//                   />
//                 }
//               />

// <Route path="/my-letters-from-ireland" exact
//                 element={
//                   <Letters
//                     newHp={newHomepage}
//                     posts={homepageId}
//                     setFounder={setFounder}
//                     boxes={peekInside.inside}
//                   />
//                 }
//               />
//               <Route path="/cw"
//                 exact
//                 element={
//                   <OfferHomepage
//                     newHp={newHomepage}
//                     posts={offerHome}
//                     postsTwo={homepageId}
//                     setFounder={setFounder}
//                     boxes={peekInside.inside}
//                   />
//                 }
//               />
//                <Route path="/christmas-ecard"
//                 exact
//                 element={
//                   <Christmas
//                     newHp={Christmas2}
//                     posts={Christmas2}
//                     setFounder={setFounder}
//                     boxes={peekInside.inside}
//                   />
//                 }
//               />
             
//               <Route
//                 path="/blog/"
//                 exact
//                 element={<SecondBlog posts={homepageId} />}
//               />

//               <Route
//                 path="/heart-in-ireland/"
//                 exact
//                 element={<HeartInIreland />}
//               />
//               <Route
//                 path="/singleblog/:slug"
//                 exact
//                 element={<Singleblog posts={homepageId} />}
//               />
             
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/form"
//                     : "/form"
//                 }
//                 element={<Form posts={formId} plans={plans} />}
//               />
//    <Route
//                 path="/get-started"
                
//                 element={<FormStarted posts={formId} plans={plans} />}
//               />

//               <Route
//                 exact
//                 path="/give-a-gift"
//                 element={
//                   <FormStartedGift posts={giftingForm}  plans={giftingPlans} />
//                 }
//               />



// <Route
//                 exact
//                 path="/gifting-form"
//                 element={
//                   <GiftingForm posts={giftingForm} plans={giftingPlans} />
//                 }
//               />


//               <Route
//                 exact
//                 path="/cw/gifting-form"
//                 element={
//                   <GiftingForm posts={giftingForm} plans={giftingPlans} />
//                 }
//               />
//               <Route
//                 exact
//                 path="/gift/gifting-form"
//                 element={
//                   <GiftingForm posts={giftingForm} plans={giftingPlans} />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/aboutus"
//                     : "/aboutus"
//                 }
//                 element={
//                   <Aboutus
//                     posts={homepageId}
//                     founder={ourFounderId}
//                     changeTab={founder}
//                     setFounder={setFounder}
//                   />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/aboutus/ourfounder"
//                     : "/aboutus/ourfounder"
//                 }
//                 element={
//                   <Aboutus
//                     posts={homepageId}
//                     founder={ourFounderId}
//                     changeTab={founder}
//                     setFounder={setFounder}
//                   />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/peek-inside"
//                     : "/peek-inside"
//                 }
//                 element={
//                   <PeekInside inside={peekInside.inside} posts={homepageId} />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/terms-of-services"
//                     : "/terms-of-services"
//                 }
//                 element={
//                   <TermsOfServices posts={termsId} homepage={homepageId} />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/privacy-policy"
//                     : "/privacy-policy"
//                 }
//                 element={
//                   <PrivacyPolicy posts={privacyId} homepage={homepageId} />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/cookie-policy"
//                     : "/cookie-policy"
//                 }
//                 element={
//                   <CookiePolicy posts={cookieId} homepage={homepageId} />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/shipping-costs"
//                     : "/shipping-costs"
//                 }
//                 element={
//                   <ShippingCosts posts={shippingId} homepage={homepageId} />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/refund-policy"
//                     : "/refund-policy"
//                 }
//                 element={
//                   <RefundPolicy posts={refundId} homepage={homepageId} />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/faqs"
//                     : "/faqs"
//                 }
//                 element={<Faqs posts={faqId} homepage={homepageId} />}
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/contact-us"
//                     : "/contact-us"
//                 }
//                 element={<ContactUs posts={homepageId} />}
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/plans"
//                     : "/plans"
//                 }
//                 element={
//                   <Plans
//                     posts={homepageId}
//                     form={formId}
//                     pastBoxes={peekInside.inside}
//                     plans={plans}
//                   />
//                 }
//               />
//               <Route
//                 path={
//                   window.location.href.indexOf("cw") !== -1
//                     ? "/cw/gifting-plans"
//                     : "/gifting-plans"
//                 }
//                 element={
//                   <GiftingPlans
//                     posts={homepageId}
//                     form={formId}
//                     pastBoxes={peekInside.inside}
//                     plans={giftingPlans}
//                   />
//                 }
//               />

//               {peekInside.inside.map((res) => {
//                 return (
//                   <Route
//                     key={res}
//                     path={"/" + res.slug}
//                     element={<SingleBox posts={homepageId} />}
//                   />
//                 );
//               })}

            
//               <Route
//                 path="/subscribe"
//                 element={<Subscribe posts={homepageId} />}
//               />
//                  <Route
//                 path="/irelands-stories"
//                 element={<Stories posts={homepageId} />}
//               />
//                    <Route path="/message/:id"   element={<SingleMessage posts={homepageId} />} />
//               <Route path="*" element={<P404 posts={homepageId} />} />
//             </Routes>
//           </ScrollToTop>
//         </Router>
//       </CookiesProvider>

<div class="mib-maintenance-wrapper">
    <div class="mib-maintenance-icon">☘️</div>
    <div class="mib-maintenance-heading">We’re Sprucing Things Up!</div>
    <div class="mib-maintenance-text">
        MyIrelandBox is currently under a wee bit of maintenance.
        <br /><br />
        💚 <strong>Back online shortly!</strong><br />
        📦 We're making improvements so your next unboxing is even more
        delightful.
    </div>
    <div class="mib-maintenance-text">
        Questions? Reach us at 
        <a class="mib-maintenance-link" href="mailto:info@myirelandbox.com"
            > info@myirelandbox.com</a
        >
    </div>
</div>
    );
  }
}
